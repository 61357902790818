<template>
	<div class="page">
		<div class="w1000">
			<topHeader @goBack="sysgoback()"></topHeader>
			<!-- 音频 -->
			<audio controls ref="commonaudio" class="disnone">
				<source src="" />
			</audio>
			<!-- 音频 -->
			<div class="top-title-col">
				<span>词书：{{myReadingObj.userBooksDTO.booksDTO.title}}</span>
				<span>共<span style="color: #f00">{{quanbu.length}}</span>词</span>
			</div>

			<!-- 筛选组 -->
			<div class="filter-list">
				<div class="filter-item" :class="{ 'checked-item': checkedTpyeIndex === index }" @click="changeType(index)"
					v-for="(item, index) in selectTypes" :key="index">
					<span>{{ item }}</span>
					<div class="line" v-if="checkedTpyeIndex === index"></div>
				</div>
			</div>

			<div class="flex-block">
				<!-- 列表 -->
				<div class="word-list">
					<div class="word-item" :class="{ 'word-checked': wordobj.word == item.word }" v-for="(item, index) in wordsData"
						:key="index">
						<img class="linsten_icon" @click="playAudio(item.phAmMp3)" src="@/assets/static/linsten_icon.png" alt="" />
						<!-- 单词 -->
						<div class="word-col" @click="handleword(item)">
							<div>{{item.word}}</div>
							<div class="word-desc">
								<span class="desc1" v-for="(sitem, sindex) in item.definitionList"
									:key="sindex">{{ sitem.part }}
									<span class="desc2" v-for="(mitem, mindex) in sitem.means"
										:key="mindex">{{ mitem }}
									</span>
								</span>
							</div>
						</div>
						<img v-if="checkedTpyeIndex<3" @click="delWord(index)" src="@/assets/static/word_icon.png" alt="消词" />
						<img v-if="checkedTpyeIndex>=3" @click="delquxiaoWord(index)" src="@/assets/static/word_icon.png" alt="取消消词" />
						<img @click="collectword(index)" src="@/assets/static/star_icon_checked.png" v-if="item.collectBookWordIdList.length>0" desc="已收藏" alt="" />
						<img @click="collectword(index)" src="@/assets/static/star_icon.png" v-else desc="未收藏" alt="" />
					</div>
				</div>
				<!-- 词卡 -->
				<wordCard v-if="isShowWordCard" class="word-card" :wordId="wordobj.wordId"></wordCard>
			</div>
			<!-- 空 -->
			<div class="data_empty_box" style="padding-top: 58px;" v-if="wordsData.length<=0">
				<div class="icon"><img class="img100" :src="require('@/assets/static/kong.png')"></img></div>
				<div class="text">暂无数据</div>
			</div>
			<!-- 空 -->
		</div>
		<collectWord :isShow="isShowcollec" :list="collectList" @clickclose="clickclose" @handlecollect="handlecollect"></collectWord>
	</div>
</template>

<script>
	import topHeader from "@/components/topHeader/topHeader.vue";
	import wordCard from "@/components/wordCard/wordCard.vue";
	import collectWord from "@/components/collectWord/collectWord.vue";
	import {mapState} from "vuex";
	import {showLoading,hideLoading} from '@/common/loading.js';
	export default {
		components: {
			topHeader,
			wordCard,
			collectWord,
		},
		data() {
			return {
				selectTypes: ['全部', '待学习', '已学习','已消词'],
				checkedTpyeIndex: 0,
				isShowCn: true, // 是否显示中文
				isShowA: false, // 是否显示排序
				startX: 0,
				startIndex: 0,
				wordsData: [],
				quanbu: [],
				daixuexi:[],
				yixuexi:[],
				yixiaoci:[],//已消磁
				editindex:0,//操作的下标
				collectList:[],//收藏本集合
				isShowcollec:false,//展示收藏
				allchecked:false,//全选按钮
				isFilter: false,//打开选项按钮
				isShowWordCard:false,//展示单词卡
				wordobj:{},//所选单词
			};
		},
		computed: {
			...mapState(["userInfo",'myReadingObj']),
		},
		created() {
			//请求数据
			this.changeType(0)
		},
		methods: {
			// 消词
			async delWord(index) {
				this.editindex = index
				var _this = this
				_this.$confirm("消灭单词("+_this.wordsData[_this.editindex].word+")之后不再进行该单词学习和复习，确定要消灭单词吗？", '消灭单词', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async() => {
					//调用消词
					let params = {
						wordId: _this.wordsData[_this.editindex].wordId,
						bookId:_this.wordsData[_this.editindex].bookId,
						userUuid:_this.userInfo.uuid,
						wordType:1,//	完成类型 0完成 1消除
					}
					let res = await _this.$common.userSuccessWord(params)
					if(res){
						_this.$message.success("消词成功！")
						_this.wordsData[_this.editindex].showMenu = false
						//加入消词那边
						if(_this.yixiaoci.length>0){
							_this.yixiaoci.unshift(_this.wordsData[_this.editindex])
						}
						let wordsData = _this.wordsData.slice().filter(item => item.wordId !== _this.wordsData[_this.editindex].wordId);
						//全部栏目数据不变
						if(_this.checkedTpyeIndex!=0){
							_this.wordsData = wordsData
						}
						if(_this.daixuexi.length>0){
							let daixuexi = _this.daixuexi.slice().filter(item => item.wordId !== _this.daixuexi[_this.editindex].wordId);
							_this.daixuexi = daixuexi
						}
						if(_this.yixuexi.length>0){
							let yixuexi = _this.yixuexi.slice().filter(item => item.wordId !== _this.yixuexi[_this.editindex].wordId);
							_this.yixuexi = yixuexi
						}
					}
				}).catch(() => {});
			},
			//取消消磁
			async delquxiaoWord(index){
				var _this = this
				this.editindex = index
				let params = {
					wordId: _this.wordsData[_this.editindex].wordId,
					bookId:_this.wordsData[_this.editindex].bookId,
					userUuid:_this.userInfo.uuid,
				}
				let res = await _this.$common.deletefailWord(params)
				if(res){
					_this.$message.success("取消消词成功！")
					_this.wordsData[_this.editindex].showMenu = false
					let wordsData = _this.wordsData.slice().filter(item => item.wordId !== _this.wordsData[_this.editindex].wordId);
					_this.wordsData = wordsData
					if(_this.checkedTpyeIndex==3&&_this.yixiaoci.length>0){
						let yixiaoci = _this.yixiaoci.slice().filter(item => item.wordId !== _this.yixiaoci[_this.editindex].wordId);
						_this.yixiaoci = yixiaoci
					}
					if(_this.daixuexi.length>0){
						//加入待学习那边
						_this.daixuexi.unshift(_this.wordsData[_this.editindex])
					}
				}
			},
			//确认收藏
			async handlecollect(list){
				var _this = this
				var params = []
				if(this.isFilter){
					//批量收藏
					
				}else{
					list.forEach((item, index) => {
						if(item.ifcollect){
							params.push({
								collectBookId: item.id,
								collectStatus: 1,//是否收藏 0否 1是
								wordId: _this.wordsData[_this.editindex].wordId
							})
						}else{
							params.push({
								collectBookId: item.id,
								collectStatus: 0,//是否收藏 0否 1是
								wordId: _this.wordsData[_this.editindex].wordId
							})
						}
					});
					var res = await this.$common.userCollectBooksWord(params)
					if(res){
						this.$message.success("成功!")
						this.clickclose(false)
						//收藏本集合刷新
						this.wordsData[this.editindex].showMenu = false
						this.wordsData[this.editindex].collectBookWordIdList = ['true']
					}
				}
			},
			//收藏本集合
			getcollect(){
			 	var _this = this
				var params = {
					userUuid:this.userInfo.uuid,
					wordId: this.wordsData[this.editindex].wordId
				}
				this.$http.post('app/word/userCollectBooks/indexWord', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data
						records.forEach((item, index) => {
							if(item.collectBookWordId&&item.collectBookWordId>0){
								item["ifcollect"] = true
							}else{
								item["ifcollect"] = false
							}
						});
						_this.collectList = records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//收藏
			collectword(index){
				this.editindex = index
				this.clickclose(true)
				//收藏本集合刷新
				this.getcollect()
			},
			//收藏弹窗
			clickclose(type){
				this.isShowcollec = type
			},
			//播放音频
			playAudio(src) {
				var _this = this
				try{
					_this.$refs.commonaudio.src = src;
					_this.$nextTick(res => {
					    _this.$refs.commonaudio.load()
					    _this.$refs.commonaudio.play()
					})
				}catch(e){
					//TODO handle the exception
				}
			},
			//选择单词
			handleword(item){
				this.wordobj = item
				this.isShowWordCard = true
			},
			//请求数据
			async getlistWord(){
				var _this = this
				return new Promise((resolve, reject) => {
				    var params = {
				    	currentPage: 1,
				    	pageSize: 8888,
				    	bookId:this.myReadingObj.bookId,
				    	userUuid:this.userInfo.uuid,
				    	type:this.checkedTpyeIndex
				    }
					showLoading()
					this.$http.post('app/word/books/listWord', params).then(function(res) {
						//数据处理
						hideLoading()
						if (res.code == 200) {
							var records = res.data.records
							records.forEach((item, index) => {
								item["definitionList"] = []
								item["checked"] = false
								item["showMenu"] = false
								try{
									item["definitionList"] = JSON.parse(item.definition)
								}catch(e){
									item["definitionList"] = []
									//TODO handle the exception
								}
							});
							resolve(records)
						} else {
							reject(false)
							_this.$confirm(res.message, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {}).catch(() => {});
						}
					})
				})
			},
			userStudyWord(){
				var _this = this
				if(this.complete){
					return false;
				}
				var params = {
					userUuid: this.userInfo.uuid,
					pageSize:58,
					currentPage:this.currentPage,
				}
				
			},
			async changeType(index) {
				this.checkedTpyeIndex = index
				this.wordsData = []
				this.startX = 0
				this.startIndex = 0
				this.allchecked = false //全选按钮
				this.isFilter = false //打开选项按钮
				if(this.checkedTpyeIndex==0){
					if(this.quanbu.length<=0){
						//请求数据
						var list = await this.getlistWord()
						this.quanbu = list
						this.wordsData = list
					}else{
						this.wordsData = this.quanbu
					}
				}else if(this.checkedTpyeIndex==1){
					if(this.daixuexi.length<=0){
						//请求数据
						var list = await this.getlistWord()
						this.daixuexi = list
						this.wordsData = list
					}else{
						this.wordsData = this.daixuexi
					}
				}else if(this.checkedTpyeIndex==2){
					if(this.yixuexi.length<=0){
						//请求数据
						var list = await this.getlistWord()
						this.yixuexi = list
						this.wordsData = list
					}else{
						this.wordsData = this.yixuexi
					}
				}else if(this.checkedTpyeIndex==3){
					if(this.yixiaoci.length<=0){
						//请求数据
						var list = await this.getlistWord()
						this.yixiaoci = list
						this.wordsData = list
					}else{
						this.wordsData = this.yixiaoci
					}
				}else{}
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/css/main.scss";

	.top-title-col {
		display: flex;
		background: #e3f0ff;
		align-items: center;
		font-size: 14px;
		justify-content: space-between;
		height: 40px;
		padding-left: 16px;
		padding-right: 24px;
		border-radius: 8px;
		margin-top: 16px;
	}

	.filter-list {
		display: flex;
		flex-wrap: nowrap;
		margin: 32px 0px 0 24px;

		.checked-item {
			font-weight: bold;
			color: #222222 !important;
		}

		.filter-item {
			padding: 0 4px;
			height: 40px;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 40px;
			font-size: 16px;
			color: #666666;
			position: relative;
			margin-right: 28px;
			cursor: pointer;

			&:hover {
				color: #444;
			}
		}

		.line {
			height: 2px;
			width: 24px;
			position: absolute;
			bottom: 1px;
			left: 50%;
			transform: translateX(-50%);
			border-radius: 2px;
			background: #51d9a5;
		}
	}

	.word-list {
		max-height: 660px;
		flex: 1;
		overflow-y: auto;
		padding-right: 20px;
		margin-right: 5px;
		border-radius: 8px;
		margin-left: -10px;

		.word-checked {
			box-shadow: 0px 3px 15.5px 0px #0000001a;
			background: #fff !important;
		}

		.word-item {
			width: 100%;
			display: flex;
			align-items: center;
			height: 74px;
			margin-bottom: 16px;
			background: #f7f7f7;
			border-radius: 8px;
			cursor: pointer;
			transition: all 0.3s;
			border: #f7f7f7 1px solid;
			padding-left: 16px;
			padding-right: 8px;
			box-sizing: border-box;
			.word-col {
				flex: 1;
				div {
					@include text-line(1);
					font-size: 16px;
					color: #222222;
					font-weight: bold;
					&:last-child {
						margin-top: 4px;
						font-size: 12px;
						color: #999999;
						font-weight: normal;
					}
				}
				.word-desc{
					width: 100%;
				}
			}

			.linsten_icon {
				margin-right: 10px;
				transition: all 0.3s;
				&:hover {
					transform: scale(1.05);
				}
			}

			&:hover {
				border: #e2e2e2 1px solid;
			}

			&:last-child {
				margin-bottom: 0px;
			}
		}

		&::-webkit-scrollbar {
			width: 6px;
		}

		&:hover {
			&::-webkit-scrollbar-thumb {
				background: #cfcfcf; // 滑块颜色
			}
		}

		&::-webkit-scrollbar-thumb {
			background: #efefef; // 滑块颜色
			border-radius: 5px; // 滑块圆角
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #c2c2c2; // 鼠标移入滑块变红
		}

		&::-webkit-scrollbar-track {
			border-radius: 6px; // 轨道圆角
			background-color: #f7f7f7; // 轨道颜色
		}
	}

	.flex-block {
		display: flex;
		margin: 16px 0 40px;

		.word-card {
			background: #f7f7f7;
		}
	}
</style>